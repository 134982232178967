import React from 'react';
import { Footer } from "../Footer";
import { Header } from "../Header";
import line from "../../assests/Group 1870 line2.png";
import image1 from "../../assests/1 (1).jpg";
import image2 from "../../assests/1 (2).jpg";
import image3 from "../../assests/1 (3).jpg";
import image4 from "../../assests/1 (4).jpg";
import image5 from "../../assests/1 (5).jpg";
import image6 from "../../assests/1 (6).jpg";
import image7 from "../../assests/1 (7).jpg";
import image8 from "../../assests/1 (8).jpg";
import image9 from "../../assests/1 (9).jpg";
import image10 from "../../assests/1 (10).jpg";
import image11 from "../../assests/1 (11).jpg";
import image12 from "../../assests/1 (12).jpg";
import image13 from "../../assests/1 (13).jpg";
import image14 from "../../assests/1 (14).jpg";
import image15 from "../../assests/1 (15).jpg";
import image16 from "../../assests/1 (16).jpg";
import image17 from "../../assests/1 (17).jpg";
import image18 from "../../assests/1 (18).jpg";
import image19 from "../../assests/1 (19).jpg";
import image20 from "../../assests/1 (20).jpg";
import image21 from "../../assests/1 (21).jpg";
import image22 from "../../assests/1 (22).jpg";
import image23 from "../../assests/1 (23).jpg";
import image24 from "../../assests/1 (24).jpg";
import image25 from "../../assests/1 (25).jpg";
import image26 from "../../assests/1 (26).jpg";
import image27 from "../../assests/1 (27).jpg";
import image28 from "../../assests/1 (28).jpg";
import image29 from "../../assests/1 (29).jpg";
import image30 from "../../assests/1 (30).jpg";
import image31 from "../../assests/1 (31).jpg";
import image32 from "../../assests/1 (32).jpg";
import image33 from "../../assests/1 (33).jpg";
import image34 from "../../assests/1 (34).jpg";
import image35 from "../../assests/1 (35).jpg";
import image36 from "../../assests/1 (36).jpg";
import image37 from "../../assests/1 (37).jpg";
import image38 from "../../assests/1 (38).jpg";
import image39 from "../../assests/1 (39).jpg";
import image40 from "../../assests/1 (40).jpg";
import image41 from "../../assests/1 (41).jpg";
import image42 from "../../assests/1 (42).jpg";
import image43 from "../../assests/1 (43).jpg";
import image44 from "../../assests/1 (44).jpg";
import image45 from "../../assests/1 (45).jpg";
import image46 from "../../assests/1 (46).jpg";
import image47 from "../../assests/1 (47).jpg";
import image48 from "../../assests/1 (48).jpg";
import image49 from "../../assests/1 (49).jpg";
import image50 from "../../assests/1 (50).jpg";
import image51 from "../../assests/1 (51).jpg";
import image52 from "../../assests/1 (52).jpg";
import image53 from "../../assests/1 (53).jpg";
import image54 from "../../assests/1 (54).jpg";
import image55 from "../../assests/1 (55).jpg";
import image56 from "../../assests/1 (56).jpg";
import image57 from "../../assests/1 (57).jpg";
import image58 from "../../assests/1 (58).jpg";
import image59 from "../../assests/1 (59).jpg";
import image60 from "../../assests/1 (60).jpg";
import image61 from "../../assests/1 (61).jpg";
import image62 from "../../assests/1 (62).jpg";
import image63 from "../../assests/1 (63).jpg";
import image64 from "../../assests/1 (64).jpg";
import image65 from "../../assests/1 (65).jpg";
import image66 from "../../assests/1 (66).jpg";
import image67 from "../../assests/1 (67).jpg";
import image68 from "../../assests/1 (68).jpg";
import image69 from "../../assests/1 (69).jpg";
import image70 from "../../assests/1 (70).jpg";
import image71 from "../../assests/1 (71).jpg";
import image72 from "../../assests/1 (72).jpg";
import image73 from "../../assests/1 (73).jpg";
import image74 from "../../assests/1 (74).jpg";
import image75 from "../../assests/1 (75).jpg";
import image76 from "../../assests/1 (76).jpg";
import image77 from "../../assests/1 (77).jpg";
import image78 from "../../assests/1 (78).jpg";
import image79 from "../../assests/1 (79).jpg";
import image80 from "../../assests/1 (80).jpg";
import image81 from "../../assests/1 (81).jpg";
import image82 from "../../assests/1 (82).jpg";
import image83 from "../../assests/1 (83).jpg";
import image84 from "../../assests/1 (84).jpg";
import image85 from "../../assests/1 (85).jpg";
import image86 from "../../assests/1 (86).jpg";
import image87 from "../../assests/1 (87).jpg";
import image88 from "../../assests/1 (88).jpg";
import image89 from "../../assests/1 (89).jpg";
import image90 from "../../assests/1 (90).jpg";
import image91 from "../../assests/1 (91).jpg";
import image92 from "../../assests/1 (92).jpg";
import image93 from "../../assests/1 (93).jpg";
import image94 from "../../assests/1 (94).jpg";
import image95 from "../../assests/1 (95).jpg";
import image96 from "../../assests/1 (96).jpg";
import image97 from "../../assests/1 (97).jpg";
import image98 from "../../assests/1 (98).jpg";
import image99 from "../../assests/1 (99).jpg";
import image100 from "../../assests/1 (100).jpg";
import image101 from "../../assests/1 (101).jpg";
import image102 from "../../assests/1 (102).jpg";
import image103 from "../../assests/1 (103).jpg";
import image104 from "../../assests/1 (104).jpg";
import image105 from "../../assests/1 (105).jpg";
import image106 from "../../assests/1 (106).jpg";
import image107 from "../../assests/1 (107).jpg";
import image108 from "../../assests/1 (108).jpg";
import image109 from "../../assests/1 (109).jpg";
import image110 from "../../assests/1 (110).jpg";
import image111 from "../../assests/1 (111).jpg";
import image112 from "../../assests/1 (112).jpg";
import image113 from "../../assests/1 (113).jpg";
import image114 from "../../assests/1 (114).jpg";
import image115 from "../../assests/1 (115).jpg";
import image116 from "../../assests/1 (116).jpg";
import image117 from "../../assests/1 (117).jpg";
import image118 from "../../assests/1 (118).jpg";
import image119 from "../../assests/1 (119).jpg";
import image120 from "../../assests/1 (120).jpg";
import image121 from "../../assests/1 (121).jpg";
import image122 from "../../assests/1 (122).jpg";
import image123 from "../../assests/1 (123).jpg";
import image124 from "../../assests/1 (124).jpg";
import image125 from "../../assests/1 (125).jpg";
import image126 from "../../assests/1 (126).jpg";
import image127 from "../../assests/1 (127).jpg";
import image128 from "../../assests/1 (128).jpg";
import image129 from "../../assests/1 (129).jpg";
import image130 from "../../assests/1 (130).jpg";
import image131 from "../../assests/1 (131).jpg";
import image132 from "../../assests/1 (132).jpg";
import image133 from "../../assests/1 (133).jpg";
import image134 from "../../assests/1 (134).jpg";
import image135 from "../../assests/1 (135).jpg";
import image136 from "../../assests/1 (136).jpg";
import image137 from "../../assests/1 (137).jpg";
import image138 from "../../assests/1 (138).jpg";
import image139 from "../../assests/1 (139).jpg";
import image140 from "../../assests/1 (140).jpg";
import image141 from "../../assests/1 (141).jpg";
import image142 from "../../assests/1 (142).jpg";
import image143 from "../../assests/1 (143).jpg";
import image144 from "../../assests/1 (144).jpg";
import image145 from "../../assests/1 (145).jpg";
import image146 from "../../assests/1 (146).jpg";
import image147 from "../../assests/1 (147).jpg";
import image148 from "../../assests/1 (148).jpg";
import image149 from "../../assests/1 (149).jpg";
import image150 from "../../assests/1 (150).jpg";
import image151 from "../../assests/1 (151).jpg";
import image152 from "../../assests/1 (152).jpg";
import image153 from "../../assests/1 (153).jpg";
import image154 from "../../assests/1 (154).jpg";
import image155 from "../../assests/1 (155).jpg";
import image156 from "../../assests/1 (156).jpg";
import image157 from "../../assests/1 (157).jpg";
import image158 from "../../assests/1 (158).jpg";
import image159 from "../../assests/1 (159).jpg";
import image160 from "../../assests/1 (160).jpg";
import image161 from "../../assests/1 (161).jpg";
import image162 from "../../assests/1 (162).jpg";
import image163 from "../../assests/1 (163).jpg";
import image164 from "../../assests/1 (164).jpg";
import image165 from "../../assests/1 (165).jpg";
import image166 from "../../assests/1 (166).jpg";
import image167 from "../../assests/1 (167).jpg";
import image168 from "../../assests/1 (168).jpg";
import image169 from "../../assests/1 (169).jpg";
import image170 from "../../assests/1 (170).jpg";
import image171 from "../../assests/1 (171).jpg";
import image172 from "../../assests/1 (172).jpg";
import image173 from "../../assests/1 (173).jpg";
import image174 from "../../assests/1 (174).jpg";
import image175 from "../../assests/1 (175).jpg";
import image176 from "../../assests/1 (176).jpg";
import image177 from "../../assests/1 (177).jpg";
import image178 from "../../assests/1 (178).jpg";
import image179 from "../../assests/1 (179).jpg";
import image180 from "../../assests/1 (180).jpg";
import image181 from "../../assests/1 (181).jpg";
import image182 from "../../assests/1 (182).jpg";
import image183 from "../../assests/1 (183).jpg";
import image184 from "../../assests/1 (184).jpg";
import image185 from "../../assests/1 (185).jpg";
import image186 from "../../assests/1 (186).jpg";
import image187 from "../../assests/1 (187).jpg";
import image188 from "../../assests/1 (188).jpg";
import image189 from "../../assests/1 (189).jpg";
import { FaDownload } from 'react-icons/fa';
import { useEffect } from "react";

function FatherDaughterDanceImagesNew() {
    useEffect(() => {
        // Set the scroll position when the component mounts
        window.scrollTo(0, 0); // Adjust the '500' value as needed
    }, []);
    const localImages = [
        image1, image2, image3, image4, image5,
        image6, image7, image8, image9, image10, image11, image12, image13, image14,
        image15, image16, image17, image18, image19, image20, image21, image22, image23, image24,
        image25, image26, image27, image28, image29, image30, image31, image32, image33, image34,
        image35, image36, image37, image38, image39, image40, image41, image42, image43, image44,
        image45, image46, image47, image48, image49, image50, image51, image52, image53, image54,
        image55, image56, image57, image58, image59,
        image60, image61, image62, image63, image64,
        image65, image66, image67, image68, image69, image70, image71, image72, image73, image74,
        image75, image76, image77, image78, image79,
        image80, image81, image82, image83, image84,
        image85, image86, image87, image88, image89,
        image90, image91, image92, image93, image94,
        image95, image96, image97, image98, image99, image100, image101, image102, image103, image104,
        image105, image106, image107, image108, image109,
        image110, image111, image112, image113, image114,
        image115, image116, image117, image118, image119,
        image120, image121, image122, image123, image124,
        image125, image126, image127, image128, image129,
        image130, image131, image132, image133, image134,
        image135, image136, image137, image138, image139, image140, image141, image142, image143, image144,
        image145, image146, image147, image148, image149, image150, image151, image152, image153, image154,
        image155, image156, image157, image158, image159, image160, image161, image162, image163, image164,
        image165, image166, image167, image168, image169,
        image170, image171, image172, image173, image174,
        image175, image176, image177, image178, image179, image180, image181, image182, image183, image184,
        image185, image186, image187, image188, image189
    ];


    const onlineImageUrls = [
        'https://lwrimages.s3.ap-south-1.amazonaws.com/1.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/2.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/3.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/4.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/5.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/6.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/7.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/8.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/9.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/10.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/12.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/13.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/14.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/15.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/16.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/17.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/18.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/19.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/20.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/21.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/22.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/23.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/24.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/25.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/26.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/27.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/28.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/29.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/30.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/31.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/32.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/33.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/34.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/35.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/36.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/37.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/38.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/39.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/40.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/41.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/42.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/43.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/44.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/45.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/46.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/47.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/48.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/49.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/50.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/51.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/52.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/53.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/54.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/55.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/56.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/57.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/58.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/59.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/60.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/61.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/62.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/63.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/64.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/65.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/66.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/67.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/68.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/69.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/70.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/71.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/72.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/73.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/74.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/75.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/76.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/77.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/78.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/79.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/80.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/81.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/82.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/83.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/84.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/85.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/86.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/87.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/88.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/89.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/90.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/91.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/92.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/93.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/94.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/95.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/96.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/97.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/98.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/99.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/100.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/101.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/102.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/103.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/104.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/105.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/106.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/107jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/108.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/109.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/110.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/111.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/112.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/113.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/114.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/115.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/116.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/117.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/118.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/119.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/120.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/121.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/122.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/123.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/124.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/125.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/126.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/127.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/128.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/129.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/130.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/131.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/132.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/133.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/134.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/135.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/136.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/137.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/138.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/139.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/140.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/141.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/142.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/143.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/144.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/145.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/146.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/147.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/148.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/149.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/150.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/151.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/152.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/153.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/154.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/155.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/156.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/157.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/158.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/159.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/160.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/161.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/162.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/163.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/164.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/165.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/166.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/167.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/168.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/169.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/170.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/171.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/172.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/173.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/174.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/175.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/176.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/177.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/178.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/179.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/180.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/181.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/182.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/183.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/184.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/185.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/186.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/187.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/188.jpg',
        'https://lwrimages.s3.ap-south-1.amazonaws.com/189.jpg'

    ];

    // const handleImageClick = (index) => {
    //   const originalImageUrl = onlineImageUrls[index];
    //   const link = document.createElement('a');
    //   link.href = originalImageUrl;
    //   link.download = `image_${index + 1}.jpg`;
    //   link.click();
    // };
    const handleDownload = (url) => {
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const blobURL = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = blobURL;
                link.download = 'image';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(blobURL);
            })
            .catch(error => console.error('Error downloading image:', error));
    };


    return (
        <>
            <Header />
            <div className="container">
                <div className="row d-flex justify-content-center ">
                    <div className="col-md-12 text-center mt-5">
                        <h4 class=" text-dark fw-bolder  mb-0 mt-5">Father-Daughter Dance Highlights</h4>
                        <img className=" img-fluid" src={line} />
                    </div>
                </div>
                <div className="row">
                    {localImages.map((image, index) => (
                        <div key={index} className="col-md-2 mb-3 mt-5">
                            <div className="image-container" style={{ position: "relative" }}>
                                <img src={image} alt={`Image ${index + 1}`} className="img-fluid rounded shadow-sm  " />
                                <button
                                    className="download-button fs-5"
                                    onClick={() => handleDownload(onlineImageUrls[index])}
                                    style={{ position: "absolute", color: "#FFC827", top: "5px", right: "5px", background: "transparent", border: "none", cursor: "pointer" }}
                                >
                                    <FaDownload />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
}

export default FatherDaughterDanceImagesNew;

